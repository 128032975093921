import { HEADERS } from "@epicbrief/shared/globals"
import { RecallBotConfig } from "@epicbrief/shared/types/UserData"
import { CreateAccountDto } from "@epicbrief/shared/types/dto/account"
import { CreateContactDto } from "@epicbrief/shared/types/dto/contact"
import { CreateDealDto } from "@epicbrief/shared/types/dto/deal"
import {
  BotJoinOption,
  CallsTableRow,
  CoachingMetrics,
  Deal,
  HubspotField,
  InviteUserDto,
  Meeting,
  MeetingWithDialogue,
  PrepTemplate,
  SalesforceField,
  SalesloftUser,
  SummaryTemplate,
  TriggerType,
  UserData,
} from "@epicbrief/shared/types/index"
import axios, {
  AxiosError,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from "axios"
import { User } from "firebase/auth"
import { SlackChannel, SlackUser } from "./types/Slack"
import { withCatch } from "./utils/errors"
import { toastError } from "./utils/toast"

const BASE_URL = process.env.VITE_APP_BACKEND_URL

export interface MergeSuggestions {
  accountSuggestions: {
    accountId: string
    suggestions: {
      id: string
    }[]
  }[]
  dealSuggestions: {
    dealId: string
    suggestions: {
      id: string
    }[]
  }[]
}

export interface MergeRequestBody {
  accounts: {
    targetAccountId: string
    sourceAccountId: string
  }[]
  deals: {
    targetDealId: string
    sourceDealId: string
  }[]
}

export interface SummarizeInput {
  template: SummaryTemplate | PrepTemplate
  intermediateCallback: (intermediateString: string) => void
  finalCallback?: () => void
  dummy?: boolean
  meetingId?: Meeting["id"]
  dealId?: Deal["id"]
  tokenLimit?: number
  isPrep?: boolean
}

export const getApiClient = (user: User, userData?: UserData | null) => {
  const axiosClient = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  })

  /**
   * Insert auth headers with an interceptor in order to allow
   * async call to get the token(s).
   */
  axiosClient.interceptors.request.use(
    async (
      config: InternalAxiosRequestConfig<any>,
    ): Promise<InternalAxiosRequestConfig<any>> => {
      const firebaseToken = await user.getIdToken()
      const slackToken = userData?.slackConnection?.auth.access_token
      const authHeaders: CreateAxiosDefaults["headers"] = {
        Authorization: `Bearer ${firebaseToken}`,
        ...(slackToken ? { "x-slack-token": slackToken } : {}),
      }
      return {
        ...config,
        headers: {
          ...config.headers,
          ...authHeaders,
        } as InternalAxiosRequestConfig["headers"],
      }
    },
  )

  async function listSlackChannels(): Promise<SlackChannel[]> {
    const res = await axiosClient.get<SlackChannel[]>("slack/channels")
    return res.status === 200 ? res.data : []
  }

  async function listSlackUsers(): Promise<SlackUser[]> {
    const res = await axiosClient.get<SlackUser[]>("slack/users")
    return res.status === 200 ? res.data : []
  }

  async function sendSlackMessage(message: string, channelId: string) {
    const res = await axiosClient.post("slack/send-message", {
      message,
      channelId,
    })
    return res.status === 200
  }

  async function handleSlackAuthCode(
    slackAuthCode: string,
    redirectUri: string,
  ) {
    const res = await axiosClient.post("slack/auth", {
      slackAuthCode,
      redirectUri,
    })
    return res.data
  }

  async function handleGoogleAuthCode(
    googleAuthCode: string,
    redirectPath: string,
  ) {
    const res = await axiosClient.post("google/auth", {
      googleAuthCode,
      redirectUri: `${window.location.origin}/${redirectPath}`,
    })

    return res.status === 200
  }

  async function handleMicrosoftAuthCode(
    microsoftAuthCode: string,
    redirectPath: string,
  ) {
    const res = await axiosClient.post("microsoft/auth", {
      microsoftAuthCode,
      redirectUri: `${window.location.origin}/${redirectPath}`,
    })

    return res.status === 200
  }

  async function handleHubspotAuthCode(
    hubspotAuthCode: string,
    redirectPath: string,
  ) {
    const res = await axiosClient.post("hubspot/auth", {
      hubspotAuthCode,
      redirectUri: `${window.location.origin}/${redirectPath}`,
    })

    return res.status === 200
  }

  async function handleSalesforceAuthCode(
    salesforceAuthCode: string,
    redirectPath: string,
  ) {
    const res = await axiosClient.post("salesforce/auth", {
      salesforceAuthCode,
      redirectUri: `${window.location.origin}/${redirectPath}`,
    })

    return res.status === 200
  }

  async function handleGongAuthCode(code: string, redirectPath: string) {
    const res = await axiosClient.post("gong/auth", {
      code,
      redirectUri: `${window.location.origin}/${redirectPath}`,
    })

    return res.status === 200
  }

  async function handleSalesloftAuthCode(code: string, redirectPath: string) {
    const res = await axiosClient.post("salesloft/auth", {
      code,
      redirectUri: `${window.location.origin}/${redirectPath}`,
    })

    return res.status === 200
  }

  async function getRequiredFields(object: "Account" | "Opportunity") {
    const response = await axiosClient.get(
      `/salesforce/${object}/required-fields`,
    )

    return response.status === 200 ? response.data : null
  }

  async function getHubspotRequiredFields(object: "Company" | "Deal") {
    const response = await axiosClient.get(`/hubspot/${object}/required-fields`)

    return response.status === 200 ? response.data : null
  }

  async function getHubspotDealPipelines(object: "Deal" | "Ticket") {
    const response = await axiosClient.get(`/hubspot/pipeline/${object}`)

    return response.status === 200 ? response.data : null
  }

  async function getHubspotFields(object: "Company" | "Deal") {
    const response = await axiosClient.get<{
      fields: HubspotField[]
    }>(`/hubspot/${object}`)

    return response.status === 200 ? response.data : null
  }

  async function sync() {
    const response = await axiosClient.get("salesforce/sync")
    return response.status === 200
  }

  async function getFields(
    object: "Account" | "Opportunity" | "Contact",
    includeReadOnlyFields?: boolean,
  ) {
    const response = await axiosClient.get<{
      fields: SalesforceField[]
    }>(`/salesforce/${object}`, {
      params: includeReadOnlyFields ? { includeReadOnlyFields: true } : {},
    })

    return response.status === 200 ? response.data : null
  }

  async function generateCrmSummary(meetingId: string, crmTemplateId: string) {
    const response = await axiosClient.post<Record<string, string>>(
      `meetings/${meetingId}/generate-crm-summary?crmTemplateId=${crmTemplateId}`,
    )

    return response.status === 200 ? response.data : null
  }

  async function getObject(
    object: "Account" | "Opportunity",
    objectId: string,
  ) {
    const response = await axiosClient.get<{
      fields: Record<string, string | null>
    }>(`salesforce/${object}/${objectId}`)

    return response.status === 200 ? response.data : null
  }

  async function updateObject(
    object: "Account" | "Opportunity",
    objectId: string,
    fields: Record<string, string>,
  ): Promise<boolean> {
    const response = await axiosClient.post(
      `salesforce/${object}/${objectId}`,
      {
        fields,
      },
    )

    return response.status === 200
  }

  async function getHubspotObject(
    object: "Company" | "Deal",
    objectId: string,
  ) {
    const response = await axiosClient.get<{
      fields: Record<string, string | null>
    }>(`hubspot/${object}/${objectId}`)

    return response.status === 200 ? response.data : null
  }

  async function searchObject(object: "Account" | "Deal", inputValue: string) {
    const response = await axiosClient.post(`crm/search/${object}`, {
      query: inputValue,
    })

    return response.status === 200 ? response.data : null
  }

  async function updateHubspotObject(
    object: "Company" | "Deal",
    objectId: string,
    fields: Record<string, string>,
  ): Promise<boolean> {
    const response = await axiosClient.post(`hubspot/${object}/${objectId}`, {
      fields,
    })

    return response.status === 200
  }

  async function getMergeSuggestions(): Promise<MergeSuggestions> {
    const response = await axiosClient.get<MergeSuggestions>("crm/merge")
    return response.data
  }

  async function mergeObjects(body: MergeRequestBody) {
    const response = await axiosClient.post("crm/merge", {
      accounts: body.accounts,
      deals: body.deals,
    })
    return response.status === 200
  }

  async function summarize(input: SummarizeInput) {
    const {
      template,
      intermediateCallback,
      finalCallback,
      dummy = false,
      meetingId,
      dealId,
      tokenLimit,
      isPrep = false,
    } = input

    let url = meetingId
      ? `${BASE_URL}summaries/${meetingId}`
      : `${BASE_URL}summaries/deal/${dealId}`
    if (isPrep) {
      url += "?isPrep=true"
    }

    const body = {
      template,
      ...(tokenLimit ? { tokenLimit } : {}),
    }

    // Need to use fetch instead of axios because axios doesn't support streaming
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
        ...(dummy ? { [HEADERS.USE_DUMMY]: "true" } : {}),
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok.")
    }

    const reader = response.body?.getReader()
    if (!reader) {
      throw new Error("No reader available.")
    }

    try {
      const decoder = new TextDecoder()
      let buffer = ""

      // eslint-disable-next-line no-constant-condition
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        const { done, value } = await reader.read()

        if (done && finalCallback) {
          finalCallback()
        }

        if (done) return true

        buffer += decoder.decode(value, { stream: true })

        let eolIndex
        // eslint-disable-next-line no-cond-assign
        while ((eolIndex = buffer.indexOf("\n\n")) >= 0) {
          const eventString = buffer.slice(0, eolIndex)
          intermediateCallback(eventString.slice(6))
          buffer = buffer.slice(eolIndex + 2)
        }
      }
    } catch (error) {
      console.error("Reader error", error)
      return false
    }
  }

  async function calcCoaching(meeting: MeetingWithDialogue) {
    const res = await axiosClient.post<{ coachingMetrics: CoachingMetrics }>(
      "coachings",
      {
        meeting,
        meetingId: meeting.id,
      },
    )
    return res.data
  }

  async function joinOrg() {
    const res = await axiosClient.post("orgs/join", {})
    return res.data
  }

  async function inviteMember(invitation: InviteUserDto) {
    try {
      const res = await axiosClient.post("orgs/invite", invitation)
      return res.status === 200
    } catch (error) {
      if (error instanceof AxiosError) {
        toastError(`Something went wrong with inviting ${invitation.email}`)
      }
      throw error
    }
  }

  async function deleteUser(userIds: UserData["id"][]) {
    try {
      const res = await axiosClient.post("users/delete", { userIds })
      return res.data
    } catch (error) {
      if (error instanceof AxiosError) {
        toastError(`Something went wrong`)
      }
      throw error
    }
  }

  async function deleteCalendar(uid: UserData["id"]) {
    const res = await axiosClient.post(`recall/calendar/disconnect`, { uid })
    return res.data
  }

  async function createAccount(accountDto: CreateAccountDto) {
    const res = await axiosClient.post<{ id: string }>("accounts", accountDto)
    return res.data.id
  }

  async function createDeal(dealDto: CreateDealDto) {
    const response = await axiosClient.post<{ id: string }>("deals", dealDto)
    return response.data.id
  }

  async function getZoomAuthUrl() {
    const res = await axiosClient.get("recall/zoom/auth")
    return res.data
  }

  async function createNote(meetingId: string) {
    const res = await axiosClient.post(`/meetings/${meetingId}/sync-note`)

    return res.status === 201
  }

  async function createContact(meetingId: string, payload: CreateContactDto) {
    const res = await axiosClient.post(
      `/meetings/${meetingId}/contacts`,
      payload,
    )

    return res.status === 201
  }

  async function connectZoomAuthWithRecall(code: string) {
    const res = await axiosClient.post(`recall/zoom/auth?code=${code}`)
    return res.data
  }

  async function disconnectZoomAuthFromRecall() {
    const res = await axiosClient.delete(`recall/zoom/auth`)
    return res.status === 204
  }

  async function updateRecallBotConfig(data: RecallBotConfig) {
    const res = await axiosClient.put(`recall/bot-config`, data)
    return res.status === 200
  }

  async function disconnectTeams() {
    const res = await axiosClient.delete(`teams/disconnect`)
    return res.status === 200
  }

  async function updateBotJoinOption(botJoinOption: BotJoinOption) {
    try {
      const res = await axiosClient.post(`users/update-bot-join-option`, {
        botJoinOption,
      })
      return res.status === 200
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status === 422) {
        toastError(`Please connect a calendar.`)
      }
      return false
    }
  }

  // TODO: placeholder dev api
  async function deleteEmbeddingsForDeal(dealId: string) {
    const res = await axiosClient.delete(`summaries/deal/${dealId}`)
    return res.status === 204
  }

  async function unscheduleBot(meetingId: string) {
    const res = await axiosClient.delete(
      `recall/bot-unschedule?meetingId=${meetingId}`,
    )
    return res.status === 200
  }

  async function scheduleBot(meetingId: string) {
    const res = await axiosClient.post(`recall/bot-schedule`, { meetingId })
    return res.status === 200
  }

  async function enrichCompany(meetingId: string) {
    return axiosClient.post<{ enrichRef: string }>(
      `/meetings/${meetingId}/enrich-company`,
    )
  }

  async function getEnrichCompany(meetingId: string) {
    const res = await axiosClient.get(`/meetings/${meetingId}/enrich-company`)
    return res.status === 200
  }

  async function enrichPerson(meetingId: string, email: string) {
    const res = await axiosClient.post(`/meetings/${meetingId}/enrich-person`, {
      customerEmail: email,
    })
    return res.status === 200
  }

  async function getEnrichPerson(meetingId: string, email: string) {
    const res = await axiosClient.get(
      `/meetings/${meetingId}/enrich-person?customerEmail=${email}`,
    )
    return res.status === 200
  }

  async function createFollowupEmail(meetingId: string, emailType: string) {
    const res = await axiosClient.post(
      `/meetings/${meetingId}/followup-email`,
      {
        emailType,
      },
    )
    return res.data
  }

  async function updateRecallBotLanguage(
    meetingId: string,
    languageCode: string,
  ) {
    const res = await axiosClient.post(
      `/meetings/${meetingId}/update-bot-language`,
      { languageCode },
    )
    return res.status === 200
  }

  async function reRun(workflowRunId: string) {
    const res = await axiosClient.post(`/workflows/runs/${workflowRunId}`)
    return res.status === 200
  }

  async function testPrompt(
    transcript: string,
    crmField: string,
    prompt: string,
  ) {
    const res = await axiosClient.post<{
      response: string
    }>("workflows/test-prompt", {
      transcript,
      crmField,
      prompt,
    })
    return res.data
  }

  async function getCalls(
    triggerType: TriggerType,
    fromDate: number,
    toDate: number,
  ) {
    const endpoints: Record<TriggerType, string> = {
      [TriggerType.GONG_WEBHOOK]: "/gong/calls",
      [TriggerType.GONG_SCHEDULED]: "/gong/calls",
      [TriggerType.SALESLOFT_SCHEDULED]: "/salesloft/calls",
      // TODO
      [TriggerType.SALESFORCE_EMAIL_WEBHOOK]: "",
    }
    let response
    try {
      response = await axiosClient.get<CallsTableRow[]>(
        endpoints[triggerType],
        {
          params: {
            fromDate,
            toDate,
          },
        },
      )
      return response.data
    } catch (error) {
      toastError(`Error fetching calls`)
      return []
    }
  }

  async function runWorkflowByCalls(workflowId: string, callIds: string[]) {
    try {
      const res = await axiosClient.post<any>(`/workflows/${workflowId}/run`, {
        callIds,
      })
      return res.status === 200
    } catch (error) {
      toastError(`Error running workflow`)
      return false
    }
  }

  async function getSalesloftUsers() {
    const res = await axiosClient.get<SalesloftUser[]>(`/salesloft/users`)
    return res.data
  }

  return {
    slack: {
      listChannels: withCatch(listSlackChannels),
      listUsers: withCatch(listSlackUsers),
      sendMessage: withCatch(sendSlackMessage),
      handleAuthCode: withCatch(handleSlackAuthCode),
    },
    google: {
      handleAuthCode: withCatch(handleGoogleAuthCode),
    },
    microsoft: {
      handleAuthCode: withCatch(handleMicrosoftAuthCode),
    },
    recall: {
      getZoomAuthUrl: withCatch(getZoomAuthUrl),
      connectZoomAuthWithRecall: withCatch(connectZoomAuthWithRecall),
      disconnectZoomAuthFromRecall: withCatch(disconnectZoomAuthFromRecall),
      updateRecallBotConfig: withCatch(updateRecallBotConfig),
      disconnectTeams: withCatch(disconnectTeams),
      unscheduleBot: withCatch(unscheduleBot),
      scheduleBot: withCatch(scheduleBot),
      updateRecallBotLanguage: withCatch(updateRecallBotLanguage),
    },
    crm: {
      getMergeSuggestions: withCatch(getMergeSuggestions),
      mergeObjects: withCatch(mergeObjects),
      createNote: withCatch(createNote),
      searchObject: withCatch(searchObject),
    },
    salesforce: {
      handleAuthCode: withCatch(handleSalesforceAuthCode),
      getRequiredFields: withCatch(getRequiredFields),
      sync: withCatch(sync),
      getFields: withCatch(getFields),
      getObject: withCatch(getObject),
      updateObject: withCatch(updateObject),
    },
    hubspot: {
      handleAuthCode: withCatch(handleHubspotAuthCode),
      getRequiredFields: withCatch(getHubspotRequiredFields),
      getHubspotFields: withCatch(getHubspotFields),
      getObject: withCatch(getHubspotObject),
      updateObject: withCatch(updateHubspotObject),
      getDealPipelines: withCatch(getHubspotDealPipelines),
    },
    gong: {
      handleAuthCode: withCatch(handleGongAuthCode),
    },
    salesloft: {
      handleAuthCode: withCatch(handleSalesloftAuthCode),
      getUsers: withCatch(getSalesloftUsers),
    },
    accounts: {
      create: withCatch(createAccount),
    },
    deals: {
      create: withCatch(createDeal),
    },
    meetings: {
      createContact: withCatch(createContact),
      enrichCompany: withCatch(enrichCompany),
      enrichPerson: withCatch(enrichPerson),
      getEnrichCompany: withCatch(getEnrichCompany),
      getEnrichPerson: withCatch(getEnrichPerson),
      createFollowupEmail: withCatch(createFollowupEmail),
      generateCrmSummary: withCatch(generateCrmSummary),
    },
    users: {
      deleteUser: withCatch(deleteUser),
      updateBotJoinOption: withCatch(updateBotJoinOption),
    },
    deleteEmbeddingsForDeal: withCatch(deleteEmbeddingsForDeal), // TODO: placeholder dev api
    summarize: withCatch(summarize),
    calcCoaching: withCatch(calcCoaching),
    orgs: {
      joinOrg: withCatch(joinOrg),
      inviteMember: withCatch(inviteMember),
    },
    deleteCalendar: withCatch(deleteCalendar),
    workflows: {
      reRun: withCatch(reRun),
      testPrompt: withCatch(testPrompt),
      runWorkflowByCalls: withCatch(runWorkflowByCalls),
      getCalls: withCatch(getCalls),
    },
  }
}
