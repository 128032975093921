import { FieldIdentifier } from "./crm"

export enum PicklistConditionType {
  IsKnown = "is known",
  IsUnknown = "is unknown",
  IsEqualToOneOf = "is equal to one of",
  IsNotEqualToOneOf = "is not equal to one of",
}

export type ConditionType = PicklistConditionType

export interface Condition {
  field: string
  type: ConditionType
  value: string
}

export interface FilterGroup {
  conditions: Array<Condition>
}

export interface Workflow {
  id: string
  name: string
  ownerRefs: string[]
  trigger: WorkflowTrigger
  action: WorkflowAction
  runs?: WorkflowRun["id"][]
  createdAt: number
  updatedAt: number
  lastSuccessfulRunAt?: number
  filterGroups?: FilterGroup[]
}

export type WorkflowTrigger =
  | GongTrigger
  | GongScheduled
  | SalesloftScheduled
  | SalesforceEmailWebhook

export interface GongTrigger {
  type: TriggerType.GONG_WEBHOOK
  jwtPublicKey: string
}

export interface GongScheduled {
  type: TriggerType.GONG_SCHEDULED
  scheduleType: "hourly" | "daily"
}

export interface SalesloftScheduled {
  type: TriggerType.SALESLOFT_SCHEDULED
  scheduleType: "hourly" | "daily"
  ownerIds: string[]
}

export interface SalesforceEmailWebhook {
  type: TriggerType.SALESFORCE_EMAIL_WEBHOOK
}

export enum TriggerType {
  GONG_WEBHOOK = "GONG",
  GONG_SCHEDULED = "GONG_SCHEDULED",
  SALESLOFT_SCHEDULED = "SALESLOFT_SCHEDULED",
  SALESFORCE_EMAIL_WEBHOOK = "SALESFORCE_EMAIL_WEBHOOK",
}

export type WorkflowAction = LlmToSalesforce | LlmToHubspot

export interface LlmToSalesforce {
  type: ActionType.LLM_TO_SALESFORCE
  config: {
    field: FieldIdentifier
    updateMode:
      | "overwrite"
      | "append"
      | "writeIfEmpty"
      | "appendWithCallDate"
      | "fusion"
    prompt: string
  }[]
}

export interface LlmToHubspot {
  type: ActionType.LLM_TO_HUBSPOT
  config: {
    field: FieldIdentifier
    updateMode:
      | "overwrite"
      | "append"
      | "writeIfEmpty"
      | "appendWithCallDate"
      | "fusion"
    prompt: string
  }[]
}

export enum ActionType {
  LLM_TO_SALESFORCE = "LLM_TO_SALESFORCE",
  LLM_TO_HUBSPOT = "LLM_TO_HUBSPOT",
}

export interface WorkflowRun {
  id: string
  workflowId: string
  ownerRefs: string[]
  startedAt: number
  completedAt?: number
  updatedAt?: number
  status?: WorkflowRunStatus
  triggerData: TriggerData
  errorMessage?: string
  result?: WorkflowRunPromptResult[]
  crmUpdateResult?: {
    id: string
    success: boolean
    errors: any[]
  }[]
}

export interface WorkflowRunPromptResult {
  field: string
  prompt: string
  response: string
}

export type TriggerData =
  | GongWorkflowTriggerData
  | GongScheduledTriggerData
  | SalesloftScheduledTriggerData
  | SalesforceEmailWebhookTriggerData

export interface GongWorkflowTriggerData {
  callData: {
    metaData: {
      id: string
      title: string
      started: string
    }
    parties: {
      id: string
      name: string
      speakerId: string
      affiliation?: string
      emailAddress?: string
      phoneNumber?: string
      title?: string
    }[]
    context: {
      system: "Salesforce" | unknown
      objects: {
        objectType: "Opportunity" | "Account"
        objectId: string
      }[]
    }[]
  }
}

export interface GongScheduledTriggerData {
  callData: {
    metaData: {
      id: string
      title: string
      started: string
    }
    context: {
      system: "Salesforce" | "HubSpot" | "MicrosoftDynamic" | "Generic"
      objects: {
        objectType: "Opportunity" | "Account"
        objectId: string
      }[]
    }[]
    parties: {
      id: string
      name: string
      speakerId: string
      affiliation?: string
      emailAddress?: string
      phoneNumber?: string
      title?: string
    }[]
  }
  fromDateTime: number
  toDateTime: number
}

export interface SalesloftScheduledTriggerData {
  callData: {
    metaData: {
      id: string
      title: string
      started: string
    }
    transcript: string
    context: {
      system: string
      objects: {
        objectType: "Opportunity" | "Account"
        objectId: string
      }[]
    }[]
  }
  fromDateTime: number
  toDateTime: number
}

export interface SalesforceEmailWebhookTriggerData {
  "soapenv:envelope": {
    "soapenv:body": {
      notifications: {
        notification: {
          sobject: {
            "sf:accountid": string[]
            "sf:description": string[]
            "sf:createddate": string[]
            "sf:whatid": string[]
            "sf:tasksubtype": string[]
          }[]
        }[]
      }[]
    }[]
  }
  callData: {
    metaData: {
      started: string
      title: string
    }
    context: {
      system: string
      objects: {
        objectType: "Opportunity" | "Account"
        objectId: string
      }[]
    }[]
  }
}

export enum WorkflowRunStatus {
  SUCCESSFUL = "Successful",
  FAILED = "Failed",
  SKIPPED = "Skipped",
  PARTIALLY_UPDATED = "Partially Updated",
}

export type CallsTableRow = {
  id: string
  title: string
  duration: number
  platform: string
  startedOn: string
  crmOpportunity: Record<string, any>
}

export interface GetCallsRequestParams {
  fromDate: number
  toDate: number
}

export interface SalesloftUser {
  guid: string
  name: string
}
