import { Box, ChakraProvider, Flex, Spinner } from "@chakra-ui/react"
import { FeatureFlagKey } from "@epicbrief/shared/types"
import loadable from "@loadable/component"
import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { AuthProvider } from "./AuthProvider"
import ProtectedRoute from "./Components/ProtectedRoute"
import PublicRoute from "./Components/PublicRoute"
import theme from "./theme"
import { ROUTES } from "./utils/constants"
import { initSentry } from "./utils/errors"
import { useFeatureFlag } from "./utils/featureFlags"
import { ToastContainer } from "./utils/toast"

initSentry()

const LoadablePage = loadable(
  ({ page }: { page: string }) => import(`./Pages/${page}.tsx`),
  {
    fallback: (
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Flex>
    ),
    cacheKey: ({ page }) => page,
  },
)

function App() {
  const isHomePageActive = useFeatureFlag(FeatureFlagKey.HomePage)
  const isCrmMappingActive = useFeatureFlag(FeatureFlagKey.CRMMapping)
  const isWorkflowsActive = useFeatureFlag(FeatureFlagKey.Workflows)
  const isChatsActive = useFeatureFlag(FeatureFlagKey.Chats)

  return (
    <>
      <ToastContainer />
      <Box minHeight="100vh" m="0">
        <ChakraProvider theme={theme}>
          <Router>
            <AuthProvider>
              <Routes>
                <Route element={<PublicRoute />}>
                  <Route
                    path={ROUTES.LOGIN}
                    element={<LoadablePage page="LoginPage" />}
                  />
                  <Route
                    path={ROUTES.LANDING}
                    element={<LoadablePage page="LoginPage" />}
                  />
                  <Route
                    path={ROUTES.SECURITY}
                    element={<LoadablePage page="SecurityPage" />}
                  />
                  <Route
                    path={ROUTES.SIGN_IN_EMAIL}
                    element={<LoadablePage page="EmailSignInPage" />}
                  />
                  <Route
                    path={ROUTES.PUBLIC_SUPPORT}
                    element={<LoadablePage page="SupportPage" />}
                  />
                </Route>
                <Route element={<ProtectedRoute />}>
                  {isWorkflowsActive && (
                    <Route
                      path={ROUTES.WORKFLOWS}
                      element={<LoadablePage page="WorkflowsPage" />}
                    />
                  )}
                  {isChatsActive && (
                    <Route
                      path={ROUTES.CHATS}
                      element={<LoadablePage page="ChatPage" />}
                    />
                  )}
                  {/* ONBOARDING */}
                  <Route
                    path={ROUTES.CONNECT_CALENDAR}
                    element={<LoadablePage page="ConnectCalendarPage" />}
                  />
                  <Route
                    path={ROUTES.CONNECT_VIDEO}
                    element={<LoadablePage page="ConnectVideoPage" />}
                  />
                  <Route
                    path={ROUTES.CONNECT_CHROME}
                    element={<LoadablePage page="ConnectChromePage" />}
                  />
                  <Route
                    path={ROUTES.CONNECT_SLACK}
                    element={<LoadablePage page="ConnectSlackPage" />}
                  />

                  {/* SINGLE MEETING */}
                  <Route
                    path={ROUTES.MEETING.MAIN}
                    element={<LoadablePage page="MeetingPage" />}
                  >
                    <Route
                      path={ROUTES.MEETING.SUMMARY}
                      element={<LoadablePage page="SummaryPage" />}
                    />
                    <Route
                      path={ROUTES.MEETING.TRANSCRIPT}
                      element={<LoadablePage page="TranscriptPage" />}
                    />
                    {/* To keep backwards compatability for extension */}
                    <Route
                      path={ROUTES.MEETING.SELECT_TEMPLATE}
                      element={
                        <LoadablePage page="SelectTemplatePageRedirect" />
                      }
                    />
                    <Route
                      path={ROUTES.MEETING.CRM}
                      element={<LoadablePage page="CrmPage" />}
                    />
                    {isHomePageActive && (
                      <Route
                        path={ROUTES.MEETING.EMAIL}
                        element={<LoadablePage page="EmailPage" />}
                      />
                    )}
                    {/* To keep backwards compatability for extension */}
                    <Route
                      path="/meeting/:id/"
                      element={<LoadablePage page="SelectTemplatePage" />}
                    />
                  </Route>
                  {/* SINGLE DEAL */}
                  <Route
                    path={ROUTES.DEAL.SUMMARY}
                    element={<LoadablePage page="DealSummaryPage" />}
                  />

                  {/* SETTINGS */}
                  <Route
                    path={ROUTES.SETTINGS.INDEX}
                    element={<LoadablePage page="IntegrationsPage" />}
                  />
                  <Route
                    path={ROUTES.SETTINGS.INTEGRATIONS}
                    element={<LoadablePage page="IntegrationsPage" />}
                  />
                  <Route
                    path={ROUTES.SETTINGS.MANAGE_USERS}
                    element={<LoadablePage page="UserManagementPage" />}
                  />
                  {isCrmMappingActive && (
                    <Route
                      path={ROUTES.SETTINGS.CRM_FIELDS_MAPPING}
                      element={<LoadablePage page="CrmFieldsMappingPage" />}
                    />
                  )}
                  {/* MISCELLANEOUS */}
                  <Route
                    path={ROUTES.SUPPORT}
                    element={<LoadablePage page="SupportPage" />}
                  />
                  <Route
                    path={ROUTES.MEETINGS}
                    element={<LoadablePage page="MeetingsPage" />}
                  />
                  {isHomePageActive && (
                    <Route
                      path={ROUTES.HOME}
                      element={<LoadablePage page="HomePage" />}
                    />
                  )}
                  <Route
                    path={ROUTES.TEMPLATES}
                    element={<LoadablePage page="TemplatesPage" />}
                  />
                  {/* INTEGRATIONS */}
                  <Route
                    path="/auth-callback"
                    element={<LoadablePage page="AuthCallbackPage" />}
                  />
                </Route>
                <Route
                  path={ROUTES.NOT_FOUND}
                  element={<LoadablePage page="NotFoundPage" />}
                />
                <Route
                  path={ROUTES.FALL_BACK}
                  element={<LoadablePage page="NotFoundPage" />}
                />
              </Routes>
            </AuthProvider>
          </Router>
        </ChakraProvider>
      </Box>
    </>
  )
}

export default App
