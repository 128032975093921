import { UseToastOptions, createStandaloneToast } from "@chakra-ui/react"
import theme from "../theme"

const { ToastContainer: Container, toast: toastFn } =
  createStandaloneToast(theme)

export const ToastContainer = Container
export const toast = toastFn

/**
 * @param unknown error - The error object or a string to be displayed in the toast.
 * @param string [customMessage] - Optional custom message for toast body.
 */
export const toastError = (error: unknown, toastOptions?: UseToastOptions) => {
  const toastId = toastOptions?.id

  // If id provided, prevent duplicate for same id
  if (toastId && toastFn.isActive(toastId)) return

  // Handle string
  if (typeof error === "string") {
    toastFn({
      title: error,
      status: "error",
      isClosable: true,
      ...toastOptions,
    })
    return
  }
  // Handle actual errors
  const err = error as any
  switch (err?.code) {
    case "auth/email-already-in-use":
      toastFn({
        title: "Email Already in Use.",
        status: "error",
        ...toastOptions,
      })
      break
    case "auth/invalid-email":
      toastFn({
        title: "Invalid email.",
        status: "error",
        ...toastOptions,
      })
      break
    default:
      toastFn({
        title: toastOptions?.description || "Something went wrong.",
        status: "error",
        description: toastOptions?.description
          ? ""
          : `Error details: ${err?.message}`,
        ...toastOptions,
      })
  }
}

export const toastSuccess = (toastOptions: UseToastOptions) => {
  const { title, description, id } = toastOptions
  if (id && toastFn.isActive(id)) return
  toastFn({
    id,
    title,
    status: "success",
    description: description ?? "",
    isClosable: true,
  })
}

export const toastWarning = (toastOptions: UseToastOptions) => {
  const { title, description, id } = toastOptions
  if (id && toastFn.isActive(id)) return
  toastFn({
    id,
    title,
    status: "warning",
    colorScheme: "orange",
    description: description ?? "",
    isClosable: true,
  })
}

export const toastInfo = (toastOptions: UseToastOptions) => {
  const { title, description, id } = toastOptions
  if (id && toastFn.isActive(id)) return
  toastFn({
    id,
    title,
    status: "info",
    description: description ?? "",
    isClosable: true,
    duration: 600,
  })
}
